<template>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-btn-toggle class="d-flex align-start" v-model="toggle_exclusive">
        <v-btn disabled id="custom-disabled">
          {{ label }}
        </v-btn>
      </v-btn-toggle>
      <v-text-field class="centered-input" v-model="value" outlined dense :rules="rules" :disabled="disabled" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggle_exclusive: 0,
    }
  },
  computed: {
    value: {
      get() {
        return this.text
      },
      set(text) {
        this.$emit('setValue', text)
      },
    },
  },
}
</script>

<style scoped>
#custom-disabled.v-btn--disabled {
  color: #424242 !important;
  opacity: 0.8;
  background-color: #ffff !important;
}
.centered-input >>> input {
  text-align: center;
}
</style>
